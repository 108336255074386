body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: radial-gradient(#000000, #21001e);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header {
  color: white;
  text-align: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75%; /* set the width of the container to 80% of the viewport width */
  margin: auto; /* center the container horizontally */
  max-width: 1024px;
}

p {
  color: white;
  text-align: center;
  font-family: 'Roboto';
  font-size: 100%;
}

h1 {
font-family: 'Phudu';
font-weight: 900;
margin-bottom: 0vw;
}

h2 {
  color: white;
  text-align: center;
  size: 2vw;
  font-family: 'Phudu';
  font-weight: 300;
  margin-bottom: 2vw;
  margin-top: 0vw;
}

h3 {
  color: white;
  font-size: 4vw;
  font-family: 'Roboto';
  margin-bottom: .5vw;
}

.button-container {
  text-align: center;
}

input[type="text"] {
  text-align: center;
  font-family: monospace;
  max-width: 1024px;
}

button {
  background-color: #ffffff;
  border: 3px,rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 2.5vw;
  font-family: monospace;
  font-weight: 300;
  margin-bottom: 10vh;
  margin-left: 5px;
  margin-right: 5px;
}

button:hover {
  background-color: #1a000f; 
  color: rgb(255, 255, 133);
}



.footer {
  height: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 80%;
  background-color: #b8b8b8;
  text-align: center;
}

.fade {
  transition: opacity 1s ease-in-out;
}

.fadeIn {
  opacity: 1;
}

.fadeOut {
  opacity: 0;
}

#quote {
  color: yellow;
  font-family: 'Roboto';
  font-style: italic;
  font-size: 2.5vw;
  margin-bottom: 0vw;;
}

input[type=text] {
  background-color: rgb(255, 255, 255);
  color: black;
}

input[type=text]:focus {
  background-color: rgb(252, 252, 126);
  border: 3px solid black;
}

a {
  color: lightblue;
  text-decoration: none;
}

a:hover {
color: yellow;
}

@media (min-width: 1024px) {
  button {
    font-size: 20px; 
  }
  h3 {
    font-size: 30px;
  }
  h3#quote {
    font-size: 20px;
  }
}